import React from 'react'

import { Layout, SEO, Article, RouteCard } from '@/components'
import { useFetchRoutes } from '@/hooks'

import { useTariffsTable } from './hooks'

export const Route = ({ pageContext, pageContext: { seo } }) => {
  const tariffsTable = useTariffsTable(pageContext.tariffs)
  const tableStations = pageContext.stations
  const routes = useFetchRoutes()
  const additionalRoutes = pageContext.additionalRoutes
    ? pageContext.additionalRoutes.map((item) => item.route.id)
    : null
  const dataAdditionalRoutes = additionalRoutes
    ? routes.filter((route) => additionalRoutes.includes(route.id))
    : null
  return (
    <Layout>
      <SEO
        description={seo.metaDesc}
        title={seo.title}
        meta={[
          {
            name: `keywords`,
            content: seo.focuskw,
          },
        ]}
      />
      <section className="section ">
        <div className="container">
          <h1 className="title_h2">{pageContext.title}</h1>
          <br />
          {pageContext.content && (
            <>
              <Article content={pageContext.content} />
              <br />
            </>
          )}
          {tableStations && (
            <>
              <h2>{`Розклад руху автобуса "${pageContext.title}"`}</h2>
              <div
                className="schedule__table-wrpr"
                style={{ marginBottom: '60px' }}
              >
                <table className="schedule__table">
                  <thead className="schedule__table-head">
                    <tr>
                      <th>Прибуття</th>
                      <th>Відправлення</th>
                      <th>Місто прибуття</th>
                      <th>Станція</th>
                    </tr>
                  </thead>
                  <tbody className="schedule__table-body">
                    {tableStations.map((row) => (
                      <tr key={row.id}>
                        <td>{row.arriving}</td>
                        <td>{row.departure}</td>
                        <td width="33%">{row.city_name}</td>
                        <td width="33%">{row.station_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {tariffsTable && (
            <>
              <h2>{`Тарифна сітка маршруту "${pageContext.title}"`}</h2>
              <div className="tariff__table-wrpr">
                <table className="tariff__table">
                  <thead className="tariff__table-head">
                    <tr>
                      {tariffsTable[0].map((col, index) => (
                        <th key={index}>{col.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="tariff__table-body">
                    {tariffsTable.map(
                      (row, index) =>
                        index !== 0 && (
                          <tr key={index} className="tariff__table-row">
                            {row.map((col, index) => (
                              <td key={index}>{col.label}</td>
                            ))}
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {dataAdditionalRoutes && (
            <>
              <h2>Вам можуть бути цікаві такі маршрути:</h2>
              <br />
              <div className="routes__list">
                {dataAdditionalRoutes.map((route) => (
                  <RouteCard key={route.id} data={route} />
                ))}
              </div>
            </>
          )}
        </div>
      </section>
    </Layout>
  )
}
