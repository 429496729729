export const useTariffsTable = (data) => {
  if (!data) {
    return null
  }
  const handleStartTable = () => {
    let from = []
    let to = []
    data.map((tariff) => {
      from.push(tariff.from_city.city)
      to.push(tariff.to_city.city)
    })
    from = [...new Set(from)]
    to = [...new Set(to)]
    const firstRow = [{ label: '' }, ...to.map((city) => ({ label: city }))]
    let table = [
      firstRow,
      ...from.map((city) => [
        { label: city },
        ...firstRow
          .filter((item, index) => index !== firstRow.length - 1)
          .map((item) => ({ label: '-' })),
      ]),
    ]

    data.map((tariff) => {
      let indexRow
      let indexCol
      table.map((row, index) => {
        if (row[0].label === tariff.from_city.city) {
          indexRow = index
        }
      })
      table[0].map((col, index) => {
        if (col.label === tariff.to_city.city) {
          indexCol = index
        }
      })
      table[indexRow][indexCol] = { label: `${tariff.price} грн.` }
    })

    return table
  }

  return handleStartTable()
}
